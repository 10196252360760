var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-sm-12" }, [
      _c("div", { staticClass: "card" }, [
        _c("div", { staticClass: "card-body form-card" }, [
          _c("div", { staticClass: "row" }, [
            _vm._m(0),
            _c("div", { staticClass: "col-sm-12 col-lg-10 col-md-10" }, [
              _c(
                "table",
                { staticClass: "table table-bordered" },
                [
                  _vm._m(1),
                  _vm._l(_vm.form.payment_methods, function (item) {
                    return _c("tr", [
                      _c("td", [_vm._v(_vm._s(item.title))]),
                      _c(
                        "td",
                        [
                          _c("el-switch", {
                            attrs: { "active-value": 1, "inactive-value": 0 },
                            model: {
                              value: item.display,
                              callback: function ($$v) {
                                _vm.$set(item, "display", $$v)
                              },
                              expression: "item.display",
                            },
                          }),
                        ],
                        1
                      ),
                    ])
                  }),
                ],
                2
              ),
            ]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "card" }, [
        _c("div", { staticClass: "card-body form-card" }, [
          _c("div", { staticClass: "row" }, [
            _vm._m(2),
            _c(
              "div",
              { staticClass: "col-sm-12 col-lg-10 col-md-10" },
              [
                _c("el-input", {
                  attrs: { type: "number", placeholder: "% giảm giá" },
                  model: {
                    value: _vm.form.coupon_discount_referral,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "coupon_discount_referral", $$v)
                    },
                    expression: "form.coupon_discount_referral",
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "card" }, [
        _c("div", { staticClass: "card-body form-card" }, [
          _c("div", { staticClass: "row" }, [
            _vm._m(3),
            _c(
              "div",
              { staticClass: "col-sm-12 col-lg-10 col-md-10" },
              [
                _c("el-input", {
                  attrs: { type: "text", placeholder: "user_id" },
                  model: {
                    value: _vm.form.unlisted_user_ids,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "unlisted_user_ids", $$v)
                    },
                    expression: "form.unlisted_user_ids",
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "card" }, [
        _c("div", { staticClass: "card-body form-card" }, [
          _c("div", { staticClass: "row" }, [
            _vm._m(4),
            _c(
              "div",
              { staticClass: "col-sm-12 col-lg-10 col-md-10" },
              [
                _c("el-input", {
                  attrs: { type: "text", placeholder: "Email" },
                  model: {
                    value: _vm.form.list_email_receive_support,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "list_email_receive_support", $$v)
                    },
                    expression: "form.list_email_receive_support",
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "card" }, [
        _c("div", { staticClass: "card-body form-card" }, [
          _c("div", { staticClass: "row" }, [
            _vm._m(5),
            _c(
              "div",
              { staticClass: "col-sm-12 col-lg-10 col-md-10" },
              [
                _c("el-input", {
                  attrs: { type: "text", placeholder: "Email" },
                  model: {
                    value: _vm.form.list_email_receive_new_order_product,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.form,
                        "list_email_receive_new_order_product",
                        $$v
                      )
                    },
                    expression: "form.list_email_receive_new_order_product",
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "card" }, [
        _c("div", { staticClass: "card-body form-card" }, [
          _c("div", { staticClass: "row" }, [
            _vm._m(6),
            _c(
              "div",
              { staticClass: "col-sm-12 col-lg-10 col-md-10" },
              [
                _vm._m(7),
                _c("br"),
                _c("el-input", {
                  attrs: {
                    type: "textarea",
                    placeholder: "Giảm giá khi đăng ký nhóm",
                  },
                  model: {
                    value: _vm.form.register_group_discount,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "register_group_discount", $$v)
                    },
                    expression: "form.register_group_discount",
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
      ]),
    ]),
    _c("div", { staticClass: "container-fluid" }, [
      _c(
        "div",
        { staticClass: "page-action" },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.save } },
            [_vm._v("Cập nhật")]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-sm-12 col-lg-2 col-md-2" }, [
      _c("b", [_vm._v("Phương thức thanh toán")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("th", [_vm._v("Tiêu đề")]),
      _c("th", [_vm._v("Hiển thị")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-sm-12 col-lg-2 col-md-2" }, [
      _c("b", [_vm._v("Giảm giá khi giới thiệu người khác (%)")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-sm-12 col-lg-2 col-md-2" }, [
      _c("b", [_vm._v("Danh sách user xem được race unlisted")]),
      _c("div", [
        _c("i", { staticStyle: { "font-size": "11px" } }, [
          _vm._v("Id cách nhau bằng dấu phẩy"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-sm-12 col-lg-2 col-md-2" }, [
      _c("b", [_vm._v("Danh sách email nhận support của user")]),
      _c("div", [
        _c("i", { staticStyle: { "font-size": "11px" } }, [
          _vm._v("Email cách nhau bằng dấu phẩy"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-sm-12 col-lg-2 col-md-2" }, [
      _c("b", [_vm._v("Danh sách email nhận thông báo có đơn hàng mới")]),
      _c("div", [
        _c("i", { staticStyle: { "font-size": "11px" } }, [
          _vm._v("Email cách nhau bằng dấu phẩy"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-sm-12 col-lg-2 col-md-2" }, [
      _c("b", [_vm._v("Giảm giá khi đăng ký nhóm")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _vm._v("Nhập liệu theo cú pháp (mỗi loại 1 dòng)\n              "),
      _c("div", [
        _c("b", [_vm._v("Số lượng#####Phần trăm giảm giá")]),
        _c("div", [
          _vm._v("VD: Đăng ký nhóm 5 người giảm 5% thì setting như sau: "),
          _c("b", [_vm._v("5#####5")]),
        ]),
        _c("div", [
          _vm._v("VD: Đăng ký nhóm 10 người giảm 10% thì setting như sau: "),
          _c("b", [_vm._v("10#####10")]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }