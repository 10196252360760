<template lang="html">
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-body form-card">
          <div class="row">
            <div class="col-sm-12 col-lg-2 col-md-2">
              <b>Phương thức thanh toán</b>
            </div>
            <div class="col-sm-12 col-lg-10 col-md-10">
              <table class="table table-bordered">
                <tr>
                  <th>Tiêu đề</th>
                  <th>Hiển thị</th>
                </tr>
                <tr v-for="item in form.payment_methods">
                  <td>{{item.title}}</td>
                  <td><el-switch v-model="item.display" :active-value="1" :inactive-value="0"></el-switch></td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-body form-card">
          <div class="row">
            <div class="col-sm-12 col-lg-2 col-md-2">
              <b>Giảm giá khi giới thiệu người khác (%)</b>
            </div>
            <div class="col-sm-12 col-lg-10 col-md-10">
              <el-input type="number" placeholder="% giảm giá" v-model="form.coupon_discount_referral"></el-input>
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-body form-card">
          <div class="row">
            <div class="col-sm-12 col-lg-2 col-md-2">
              <b>Danh sách user xem được race unlisted</b>
              <div><i style="font-size: 11px;">Id cách nhau bằng dấu phẩy</i></div>
            </div>
            <div class="col-sm-12 col-lg-10 col-md-10">
              <el-input type="text" placeholder="user_id" v-model="form.unlisted_user_ids"></el-input>
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-body form-card">
          <div class="row">
            <div class="col-sm-12 col-lg-2 col-md-2">
              <b>Danh sách email nhận support của user</b>
              <div><i style="font-size: 11px;">Email cách nhau bằng dấu phẩy</i></div>
            </div>
            <div class="col-sm-12 col-lg-10 col-md-10">
              <el-input type="text" placeholder="Email" v-model="form.list_email_receive_support"></el-input>
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-body form-card">
          <div class="row">
            <div class="col-sm-12 col-lg-2 col-md-2">
              <b>Danh sách email nhận thông báo có đơn hàng mới</b>
              <div><i style="font-size: 11px;">Email cách nhau bằng dấu phẩy</i></div>
            </div>
            <div class="col-sm-12 col-lg-10 col-md-10">
              <el-input type="text" placeholder="Email" v-model="form.list_email_receive_new_order_product"></el-input>
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-body form-card">
          <div class="row">
            <div class="col-sm-12 col-lg-2 col-md-2">
              <b>Giảm giá khi đăng ký nhóm</b>
            </div>
            <div class="col-sm-12 col-lg-10 col-md-10">
              <div>Nhập liệu theo cú pháp (mỗi loại 1 dòng)
                <div><b>Số lượng#####Phần trăm giảm giá</b>
                  <div>VD: Đăng ký nhóm 5 người giảm 5% thì setting như sau: <b>5#####5</b></div>
                  <div>VD: Đăng ký nhóm 10 người giảm 10% thì setting như sau: <b>10#####10</b></div>
                </div>
              </div>
              <br>
              <el-input type="textarea" placeholder="Giảm giá khi đăng ký nhóm" v-model="form.register_group_discount"></el-input>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid">
      <div class="page-action">
        <el-button type="primary" @click="save">Cập nhật</el-button>
      </div>
    </div>
  </div>
</template>

<script>

import { Input, Button, Switch, Message } from 'element-ui';
import { mapState } from 'vuex'
import { Toast } from 'vuex-toast'
import MyEditor from 'src/components/UIComponents/Editor'
import MyImage from 'src/components/UIComponents/Image'
import { mapActions } from 'vuex'
import { ADD_TOAST_MESSAGE } from 'vuex-toast'

export default {
  components: {
    ElInput: Input,
    ElButton: Button,
    ElSwitch: Switch,
    MyEditor,
    MyImage,
    Toast,
    Message
  },

  computed: {
    form() {
        return this.$store.state.allSettings
    }
  },

  mounted() {
    this.$store.dispatch('setPageTitle', 'Cấu hình chung');
    this.$store.dispatch('setCurrentActions', [{
      label: 'Cập nhật',
      type: 'primary',
      icon: '',
      callback: this.save
    }])

    this.$store.dispatch('fetchAllSetting');
  },

  methods: {
    save() {
      var self = this;
      let form = {...self.form};
      self.$store.dispatch('updateSetting', form).then(res => {
        if (res.success) {
          Message({
            message: 'Cập nhật thành công',
            type: 'success'
          });
        } else {
          if (res.errors) {
            for (let err in res.errors) {
              Message({
                message: res.errors[err][0],
                type: 'error'
              });
            }
          } else {
            Message({
              message: res.message,
              type: 'error'
            });
          }
        }
      }, error => {
        console.error(error);
      });
    },
    ...mapActions({
      addToast: ADD_TOAST_MESSAGE
    }),
    toast(text, type) {
      this.addToast({
        text,
        type: type,
        dismissAfter: 2000
      })
    }
  },
  watch: {
    'form.coupon_discount_referral': function(newVal, oldVal) {
      if (newVal < 1 || newVal > 100) {
        Message({
          message: "Giảm giá phải nằm trong khoảng từ 1-100",
          type: 'error'
        });
      }
    }
  },
  destroyed(){
    this.$store.dispatch('setCurrentActions', []);
    this.form = {};
    this.$store.state.allSettings = [];
  }
}
</script>
